.students {
    li {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        div.studentIcon {
            background-image: url('./../images/student.png');
            background-size: contain;
            background-repeat: no-repeat;
            align-self: center;
            width: 28px;
            height: 28px;
            margin: 0 20px 0 10px;
            padding: 0;
        }

        span {
            font-size: 18px;
            display: block;
            font-weight: 300;
            width: 100%;
            align-self: center;
        }
    }
}
