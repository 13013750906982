.mainContent {
    background-color: $main-color;
    background-image: url('../images/tlo.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    margin: 0;

    p.tittles {
        margin-top: 10vh;
        font-size: 16px;
        margin-left: 130px;
        color: #ffffff;
    }

    h1 {
        margin-left: 130px;
        font-size: 42px;
        font-weight: 200;
        color: #ffffff;

        span {
            font-weight: 600;
            color: #ffffff;
        }
    }

    p.description {
        font-size: 14px;
        margin: 0 0 15vh 130px;
        border-left: 5px solid #ffffff;
        padding-left: 15px;
        line-height: 28px;
        color: #ffffff;

        a {
            font-weight: 900;
        }
    }

    .welcome {
        width: 75%;
        margin-left: 130px;
        padding: 4%;
        background-color: #ffffff;
        position: relative;
        -webkit-box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);
        -moz-box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);
        box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);

        img {
            position: absolute;
            top: -80px;
            right: 0;
            width: 150px;
        }

        p {
            width: 65%;
            font-family: $font-article;
            color: $font-color;
        }

        .buttons {
            width: 360px;
            position: absolute;
            bottom: -75px;
            right: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            a.btn-sausage {
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              border-radius: 45px;
              font-family: $font;
              color: #ffffff;
              font-size: 18px;
              background: #090e74;
              padding: 10px 20px 10px 20px;
              border: solid #090e74 3px;
              transition: .2s;
              text-decoration: none;
              color: white;
              white-space: nowrap;
              &:hover {
                background: #ffffff;
                text-decoration: none;
                color: #090e74;
              }
              }


            }

            .publications {
                margin-left: 20px;

            }
        }

}
@media all and (max-width: 1230px) {
    .mainContent {
        p.tittles {
            font-size: 13px;
            margin-left: 110px;
            margin-top: 12vh;
        }

        h1 {
            margin-left: 110px;
            font-size: 36px;
        }

        p.description {
            font-size: 12px;
            margin: 0 0 15vh 110px;
            line-height: 24px;
        }

        .welcome {
            margin-left: 110px;
            padding: 4.5%;

            img {
                top: -60px;
                width: 110px;
            }

            p {
                width: 75%;
                font-size: 13px;
            }

            .buttons {
                width: 340px;
            }
        }
    }
}
@media all and (max-width: 950px) {
    .mainContent {
        height: auto;

        p.tittles {
            margin-left: 40px;
            margin-top: 50px;
        }

        h1 {
            margin-left: 40px;
        }

        p.description {
            margin-left: 40px;
            margin-bottom: 50px;
            line-height: 25px;
        }

        .welcome {
            width: 85%;
            margin-left: 40px;
            padding: 20px;
            margin-bottom: 200px;

            .buttons {
                // width: 320px;

                .news,
                .publications {
                    width: 150px;
                }
            }
        }
    }
}
@media all and (max-width: 480px) {
    .mainContent {
        p.tittles {
            margin: 30px auto 10px;
            text-align: center;
        }

        h1 {
            margin: 20px auto 10px;
            text-align: center;
            font-size: 30px;
        }

        p.description {
            margin: 20px auto 50px;
            line-height: 23px;
            text-align: center;
            border: none;
        }

        .welcome {
            width: 90%;
            margin-left: auto;
            margin-right: auto;

            img {
                position: absolute;
                top: -50px;
                right: 20%;
                width: 50px;
            }

            p {
                width: 100%;
            }
            .publications {
              width: 150px;
            }
        }
    }
}
