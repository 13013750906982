.leftPanel {
    height: 100vh;
}
//menu-hamburger

.menu {
    margin: 15px;
    width: 35px;
    z-index: 3;
    display: block;
    cursor: pointer;
    display: none;

    .bar1,
    .bar2,
    .bar3 {
        width: 35px;
        height: 5px;
        background-color: $main-color;
        margin: 6px 0;
        transition: 0.4s;
    }
}

.change {
    .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
    }

    .bar2 {
        opacity: 0;
    }

    .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
    }
}
/*circles PL ENG*/
.lang {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 15px 0;

    p {
        width: 50px;
        height: 50px;
        border: 1px solid $main-color;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        background-color: $main-color;
        color: #ffffff;
        font-weight: 700;
    }

    p.lang-alt {
        background-color: #ffffff;
        color: $main-color;


        &:hover {
            background-color: $main-color;
            color: #ffffff;
            cursor: pointer;
            transition: 0.2s;
        }
    }
    .en {
          margin-left: 20px;
    }
}
/*adress-professor*/
.adress {
    margin-top: 8vh;
    position: relative;

    .photoDiv {
        width: 180px;
        height: 180px;
        background-color: #a6b4a6;
        background-image: url('../images/stanislaw_bielecki.jpg');
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: 50% 25%;
        border-radius: 50%;
        position: absolute;
        bottom: -20px;
        right: -90px;
        z-index: 1;
        -webkit-box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);
        -moz-box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);
        box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);

        #photo {
            vertical-align: top;
            width: 180px;
            border-radius: 50%;
            opacity: 0;
        }
    }

    p {
        color: $font-color;
        font-family: $font-article;
        font-size: 14px;
        line-height: 25px;
        text-align: right;
        margin-right: 110px;

        a {
            color: $main-color;
            font-weight: 600;
        }
    }
}

.mobile {
    display: none;
}
//menu-nav ---

nav {
    margin-top: 15vh;

    ul {
        list-style: none;

        a {
            color: $main-color;
            font-size: 18px;
            text-decoration: none;
            line-height: 30px;

            span {
                color: $main-color;
                font-size: 18px;
                visibility: hidden;
            }

            &:hover {
                color: $main-color;
                font-weight: 700;

                span {
                    visibility: visible;
                }
            }
        }
    }
}
@media all and (max-width: 1230px) {
    .lang {
        p {
            width: 45px;
            height: 45px;
            font-size: 13px;
        }
    }

    .adress {
        p {
            font-size: 11px;
            margin-right: 80px;
        }

        .photoDiv {
            width: 150px;
            height: 150px;
            bottom: -20px;
            right: -75px;

            #photo {
                width: 150px;
            }
        }
    }

    nav {
        ul {
            a {
                font-size: 14px;
                line-height: 26px;

                span {
                    font-size: 14px;
                }
            }
        }
    }
}
@media all and (max-width: 950px) {
    .leftPanel {
        height: auto;
    }

    .menu {
        display: block;
    }

    nav {
        display: none;
        width: 100%;
        margin: 10px 0;
        padding: 20px;
        background-color: $main-color;

        li {
            a {
                padding: 10px;
                color: #ffffff;
                font-size: 16px;

                span {
                    color: #ffffff;
                }

                &:hover {
                    color: #ffffff;
                    font-weight: 700;

                    span {
                        visibility: visible;
                    }
                }
            }
        }
    }

    .show {
        display: block;
    }

    .desktop {
        display: none;
    }

    div.mobile {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin: 0;
        padding: 15px 0;
        border-bottom: 2px solid #ffffff;

        .photoDiv {
            width: 120px;
            height: 120px;
            position: static;
            margin: 0 15px 0 40px;

            #photo {
                width: 120px;
            }
        }

        p {
            padding-top: 10px;
            padding-left: 10px;
            margin-right: 0;
            text-align: left;
        }
    }

    .lang {
        position: absolute;
        top: 0;
        right: 0;
        /*margin: 3px 3px 0 0;*/
        margin: 9px 5px 0 0;

        p {
            width: 45px;
            height: 45px;
        }
    }
}
@media all and (max-width: 405px) {
    div.mobile {
        padding: 10px;

        .photoDiv {
            width: 100px;
            height: 100px;
            margin: 0;

            #photo {
                width: 100px;
            }
        }

        p {
            line-height: 19px;
        }
    }
}
