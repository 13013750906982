@import "modules/base";

/*partials*/

/*main-page*/
@import "partials/grid";
@import "partials/left";
@import "partials/right";

/*PL*/
@import "partials/nauka";
@import "partials/omnie";
@import "partials/publikacje";
/*gallery*/
@import "partials/swipebox";
@import "partials/fotoalbum";

@import "partials/wypromowanidoktorzy";
@import "partials/aktualnistudencidoktoranci";
@import "partials/ksztalcenie";
@import "partials/nowosci"
