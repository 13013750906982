.tab {
    .mainContent {
        .underline {
            font-weight: 400;
            padding-bottom: 20px;
        }

        ol.documents {
            list-style: decimal;
            padding-left: 35px;

            li {
                line-height: 20px;
                color: $main-color;
                font-weight: 300;
                width: 100%;
                padding: 0 0 20px;
                display: inline-flex;
                flex-wrap: wrap;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;

                div.articleIcon {
                    background-image: url('./../images/article1.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    align-self: flex-start;
                    width: 24.14px;
                    height: 32.19px;
                    margin: 0;
                    padding: 0;
                }

                p {
                    width: 100%;
                    padding: 0 10px;
                    align-self: center;
                    font-weight: 300;
                    font-size: 15px;

                    span {
                        font-weight: 500;
                        font-size: 15px;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}
@media all and (max-width: 950px) {
    .tab {
        .mainContent {
            .underline {
                padding: 0 20px;
            }

            ol.documents {
                list-style: decimal;
                padding-left: 20px;
            }
        }
    }
}
