* {
    box-sizing: border-box;
}
$base-width: 100%/12;

.container {
    width: 100%;
    margin: 0 auto;
    /* overflow: hidden;*/
    height: 100vh;

    .row {
        margin-top: 0;
        margin-bottom: 0;

        &:after,
        &:before {
            content: "";
            display: table;
            clear: both;
        }

        [class*="col-"] {
            min-height: 1px;
            float: left;
            width: $base-width;
            padding: 0;
            word-wrap: break-word;
        }
        @for $i from 1 through 12 {
            .col-#{$i} {
                width: $base-width * $i;
            }
        }
    }
}
/*---media-siatka---*/
@media all and (max-width: 950px) {
    .container {
        height: auto;

        .offset {
            display: none;
        }

        .row {
            display: flex;
            flex-direction: column;

            .col-3,
            .col-7,
            .col-8 {
                width: 100%;
                max-width: 100vw;
            }

            .leftPanel {
                width: 100%;
                max-width: 100vw;
            }

            .rightPanel {
                width: 100%;
                max-width: 100vw;
            }
        }
    }
}
