$main-color: #090e74;
$font-color: #05072f;
$font: 'Work Sans', 'Arial', sans-serif;
$font-article: 'Roboto Slab', 'Work Sans', 'Arial', sans-serif;

*{
   font-family: $font;
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   color: $main-color;
   text-decoration: none;
}

body{
  width: 100%;
  margin: 0 auto;
}

a{
    text-decoration: none;
    color: #ffffff;
}
