.tab {
    //menu-hamburger
    .menu {
        .bar1,
        .bar2,
        .bar3 {
            background-color: #ffffff;
        }
    }

    .topPanel {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        background-color: $main-color;
        padding: 0;
        position: sticky;
        top: -75px;
        z-index: 3;
        transition: 0.5s;

        .top {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: 75px;

            .lang {
                padding: 0;
                margin: 15px 0;

                p {
                    border-color: #ffffff;
                }
            }

            p.tittles {
                padding-top: 12px;
                padding-left: 15px;
                font-size: 16px;
                line-height: 25px;
                color: #ffffff;

                a {
                    font-size: 19px;
                    font-weight: 600;
                }
            }
        }
    }

    .adress.desktop {
        margin-top: 100px;
        position: static;

        .photoDiv {
            width: 100px;
            height: 100px;
            position: static;
            margin: 0 auto;

            #photo {
                width: 100px;
            }
        }

        p {
            text-align: center;
            margin: 10px auto;
        }
    }

    .mainContent {
        background-color: #ffffff;
        background-image: none;
        height: auto;
        margin: 0;
        padding: 0 10px;
        width: 100%;

        h1 {
            margin: 0;
            padding: 30px 0 40px;
            width: 100%;
            word-wrap: break-word;
            font-family: $font;
            color: $main-color;
            font-size: 38px;
            width: 100%;
            font-weight: 600;
        }

        h2,
        h3 {
            width: 100%;
        }

        .text {
            .structure {
                width: 216px;
                height: 169px;
            }

            .celmat {
                width: 250px;
                height: 308px;
                float: left;
                margin: 20px 20px 20px 0;
            }

            .celmatDescription {
                margin-right: 0;
                margin-left: 20px;
            }

            &.header {
                padding: 25px 0;
                font-size: 18px;
                font-weight: 400;
                text-align: left;
            }
        }

        .firstPdf {
            width: 100%;
            display: inline-flex;
            align-content: center;
            margin: 20px 0 0;
            flex-direction: row;
            flex-wrap: nowrap;

            a {
                /*width: 100%;*/
                width: 32px;
                display: block;
                margin: 0 20px 0 0;
            }

            span {
                display: block;
                font-weight: 300;
                width: 100%;
                align-self: center;
            }
        }

        ol.documents,
        ul.documents {
            display: block;
            width: 100%;
            list-style: none;

            li {
                width: 100%;
                display: inline-flex;
                align-content: center;
                flex-direction: row;
                flex-wrap: nowrap;
                margin: 5px 0;

                a {
                    width: 32px;
                    display: block;
                    margin: 0 20px 0 0;
                }

                span {
                    display: block;
                    font-weight: 300;
                    width: 100%;
                    align-self: center;
                }
            }
        }
    }
}
/*
.sticky {
    position: fixed;
    top: 0;
    margin: 0;
}*/
@media all and (max-width: 1230px) {
    .tab .mainContent .firstPdf {
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;

        span {
            font-size: 14px;
        }
    }

    ol.documents,
    ul.documents {
        li {
            span {
                font-size: 14px;
            }
        }
    }
}
@media all and (max-width: 950px) {
    .tab {
        .topPanel {
            height: 75px;
            position: static;

            .menu {
                display: block;
                color: #ffffff;
            }

            .top {
                width: 0;

                .lang {
                    margin: 9px 5px 0 0;
                }

                p.tittles {
                    position: absolute;
                    top: 0;
                    left: 80px;
                    margin: 3px 3px 0 0;
                }
            }
        }

        nav {
            margin: 0;

            ul {
                li {
                    a {
                        padding: 10px;
                        font-size: 16px;
                    }
                }
            }
        }

        section.col-1 {
            height: 0;
            margin: -2px;
        }

        .mainContent {
            h1 {
                font-size: 30px;
                font-weight: 600;
                padding: 20px 20px 30px;
            }

            .text {
                &.header {
                    padding: 25px 20px;
                }

                .celmat {
                    display: block;
                    float: none;
                    margin: 20px auto;
                }
            }
        }

        .firstPdf {
            padding: 0 20px;
        }

        .documents {
            padding: 0 20px;
        }

        .adress.mobile {
            border-top: 1px solid $main-color;
            margin-top: 30px;
        }
    }
}
@media all and (max-width: 480px) {
    .tab {
        .topPanel {
            height: 140px;

            .top {
                p.tittles {
                    top: 65px;
                    left: 0;
                    margin: 0 auto;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
