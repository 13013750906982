.nowosci {
  .mainContent {
      .underline {
          font-weight: 400;
        }
      .news-item {
        margin-top: 35px;
      }
      p {
        color: black;
      }
      .button-container {
        margin: 25px 0 70px 0;
        .btn-sausage {
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 45px;
          font-family: $font;
          float: right;
          color: #ffffff;
          font-size: 15px;
          background: #090e74;
          padding: 10px 20px 10px 20px;
          border: solid #090e74 3px;
          transition: .2s;
          text-decoration: none;
          &:hover {
            background: #ffffff;
            text-decoration: none;
            color: #090e74;
          }

          }
        }

      }
      @media (max-width: 950px) {
        .news-item {
          padding: 45px;
        }
      }
    }
.newsPage {
  .newsTop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 75px;
    -webkit-box-shadow: 2px 2px 10px 0 #05072f;
    -moz-box-shadow: 2px 2px 10px 0 #05072f;
    box-shadow: 2px 2px 10px 0 #05072f;
    h1 {
      text-align: center;
      padding-bottom: 0;
    }
    span {
      text-align: center;
    }

      p {
        color: black;
        max-width: 800px;
        margin-top: 50px;
        line-height: 35px;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
      }

  }

  .button-container {
    margin: 25px 0 70px 0;
    .btn-sausage {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 45px;
      font-family: $font;
      float: right;
      color: #ffffff;
      font-size: 15px;
      background: #090e74;
      padding: 10px 20px 10px 20px;
      border: solid #090e74 3px;
      transition: .2s;
      text-decoration: none;
      &:hover {
        background: #ffffff;
        text-decoration: none;
        color: #090e74;
      }

      }
    }

}
