.text {
    display: inline-block;
    float: left;
    font-family: $font;
    color: $main-color;
    border: none;
    line-height: 20px;
    word-wrap: break-word;
    font-size: 15px;
    text-align: justify;
    width: 100%;
    font-weight: 300;

    img {
        display: inline-block;
        float: right;
        width: 200px;
        margin: 20px 0 20px 20px;
        box-shadow: 2px 2px 10px 0 #05072f;
    }
}
@media all and (max-width: 950px) {
    .text {
        padding: 0 20px;
    }
}
@media all and (max-width: 480px) {
    .text {
        display: block;
        float: none;

        img {
            display: block;
            float: none;
            margin: 15px auto;
        }
    }
}
