.gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;

    .galleryItem {
        width: 33%;
        padding: 10px;

        .galleryImg {
            align-self: center;
            width: 100%;
            cursor: pointer;
            /*box-shadow: 2px 2px 10px 0 #05072f;*/
        }
    }
}
@media all and (max-width: 950px) {
    .gallery {
        padding: 0 10px;

        .galleryItem {
            width: 50%;
        }
    }
}
