.tab {
    .mainContent {
        .video {
            width: 100%;
            max-width: 1080px;
            display: block;
            height: auto;
            margin: 0 auto 30px;
        }

        table {
            border-collapse: collapse;
            margin: 20px auto;

            tr {
                td {
                    border: 1px solid $main-color;
                    width: 180px;
                    padding: 10px;
                    text-align: left;

                    &:nth-child(1) {
                        background-color: $main-color;
                        color: #ffffff;
                        border-bottom: 1px solid #ffffff;
                        width: 450px;
                    }
                }
            }
        }

        h2 {
            /*margin-top: 20px;*/
            font-weight: 300;
        }
    }
}
@media all and (max-width: 950px) {
    .tab {
        .mainContent {
            h2 {
                font-size: 20px;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
            }
        }

        table {
            tr {
                td {
                    font-size: 14px;
                }
            }
        }
    }
}
