.doctors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;

    .doctor {
        width: 50%;
        padding: 0 40px 60px 0;

        .header {
            p {
                font-size: 24px;
                padding: 0;
                border-bottom: 1px solid $main-color;
                box-shadow: 2px 2px 10px 0 rgba(5,7,47,1);
                box-shadow: 0 6px 10px -8px rgba(5,7,47,1);
            }
        }

        .content {
            padding-top: 20px;
            display: inline-flex;
            flex-wrap: wrap;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            img {
                align-self: flex-start;
                display: block;
                width: 26px;
            }

            div.bookIcon,
            div.inIcon,
            div.wwwIcon {
                background-image: url('./../images/book.png');
                background-size: contain;
                background-repeat: no-repeat;
                align-self: flex-start;
                width: 26px;
                height: 32px;
                padding: 0;
            }

            div.wwwIcon {
                background-image: url('./../images/www.png');
            }

            div.inIcon {
                background-image: url('./../images/in3.png');
            }

            p {
                width: 100%;
                padding: 0 10px;
                align-self: center;
                font-weight: 300;
                font-size: 15px;
            }
        }
    }
}
@media all and (max-width: 1230px) {
    .doctors {
        .doctor {
            width: 100%;
            max-width: 600px;
            padding: 0 40px 60px;
        }
    }
}
@media all and (max-width: 950px) {
    .doctors {
        justify-content: center;

        .doctor {
            padding: 0 20px 40px;
        }
    }
}
